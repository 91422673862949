<div class="container" data-test="marketing-modal">
  <div>
    <div class="text">
      <div class="h4_h3-regular header">{{ 'REFERRAL_PROGRAM.MODAL.TITLE' | translate }}</div>
      <p class="p-md">{{ 'REFERRAL_PROGRAM.MODAL.BODY' | translate }}</p>
    </div>
    <div class="actions">
      <ni-button-rb data-test="referral-button" (click)="onReferAFriend()">
        {{ 'REFERRAL_PROGRAM.MODAL.BUTTON' | translate }}
      </ni-button-rb>
      <div class="p-md skip" data-test="skip-button" (click)="onSkip()">
        {{ 'REFERRAL_PROGRAM.MODAL.SKIP' | translate }}
      </div>
    </div>
  </div>
  <div class="img-container">
    <img src="/assets/img/referral-program-modal.svg" alt="completed-icon" />
  </div>
</div>
