import { inject, Injectable } from '@angular/core';

import { CustomerReviewModalComponent } from '../../shared/components/customer-review-modal/customer-review-modal.component';
import { InteractionType } from '../models/interaction-type.enum';
import { DynamicDialogService } from './dynamic-dialog.service';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerReviewModalService {
  private trackingService = inject(TrackingService);
  private dynamicDialogService = inject(DynamicDialogService);
  private customerReviewUrl = 'https://www.trustpilot.com/evaluate/nextinsurance.com';

  openCustomerReviewModal(): void {
    this.dynamicDialogService.open(CustomerReviewModalComponent, {
      showHeader: false,
      dismissableMask: false,
      data: {
        reviewUrl: this.customerReviewUrl,
      },
    });
  }

  trackCustomerReviewModalSkip(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'customer-review-modal',
        name: 'skip-button',
      },
      true,
    );
  }

  trackCustomerReviewModalLeaveReview(): void {
    this.trackingService.track(
      {
        interactionType: InteractionType.Click,
        placement: 'customer-review-modal',
        name: 'leave-review-button',
      },
      true,
    );
  }
}
