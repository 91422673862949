import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import logger from '@next-insurance/logger';
import { NiButtonRbComponent } from '@next-insurance/ni-material';
import { NiTrimInputDirective } from '@next-insurance/ni-material/ni-trim-input-directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  client as braintreeClient,
  HostedFieldFieldOptions,
  HostedFields,
  hostedFields,
  HostedFieldsEvent,
  HostedFieldsTokenizePayload,
} from 'braintree-web';
import { HostedFieldsHostedFieldsFieldData, HostedFieldsHostedFieldsFieldName } from 'braintree-web/hosted-fields';

import { FormErrorMessageDirective } from '../../../shared/directives/form-validation/form-error-message.directive';
import { FormSubmitDirective } from '../../../shared/directives/form-validation/form-submit.directive';
import { CreditCardIconsComponent } from '../credit-card-icons/credit-card-icons.component';

@Component({
  selector: 'ni-braintree-payment-form',
  templateUrl: './braintree-payment-form.component.html',
  styleUrls: ['./braintree-payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormSubmitDirective,
    NgFor,
    FormErrorMessageDirective,
    NgClass,
    NgIf,
    NiButtonRbComponent,
    TranslateModule,
    CreditCardIconsComponent,
    NiTrimInputDirective,
  ],
})
export class BraintreePaymentFormComponent implements OnInit, OnChanges {
  readonly cardNumberSelector = 'cardNumber';
  readonly cardExpirationSelector = 'cardExpiration';
  readonly cardSecurityCodeSelector = 'cardSecurityCode';
  @Input() providerToken: string;
  @Input() isSubmitting: boolean;
  @Input() isSubmitDisabled: boolean;
  @Output() submitted = new EventEmitter<string>();
  @Output() error = new EventEmitter<string>();
  isBraintreeFormValid = false;
  holderNameForm: FormGroup<{
    holderName: FormControl<string>;
  }>;

  hostedFieldsInstance: HostedFields;
  errors: Partial<Record<HostedFieldsHostedFieldsFieldName, boolean>> = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initHolderNameForm();
    await this.initBraintree();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.providerToken && !changes.providerToken.isFirstChange()) {
      await this.initBraintree();
    }
  }

  private async initBraintree(): Promise<void> {
    try {
      const clientInstance = await braintreeClient.create({ authorization: this.providerToken });
      this.hostedFieldsInstance = await hostedFields.create({
        client: clientInstance,
        ...this.getBraintreeConfig(),
      });

      this.hostedFieldsInstance.on('validityChange', (event: HostedFieldsEvent) => {
        this.handleValidityChange(event);
      });

      this.hostedFieldsInstance.on('blur', (event: HostedFieldsEvent) => {
        this.handleInputBlur(event);
      });
    } catch (error) {
      await this.handleBraintreeError('Error while creating braintree payment instance', error);
    }
  }

  async submit(): Promise<void> {
    try {
      const tokenizePayload: HostedFieldsTokenizePayload = await this.hostedFieldsInstance.tokenize({
        cardholderName: this.holderNameForm.controls.holderName.value,
      });
      this.isSubmitDisabled = true;
      this.changeDetectorRef.markForCheck();
      this.submitted.emit(tokenizePayload.nonce);
    } catch (error) {
      await this.handleBraintreeError('Error while creating braintree nonce', error);
    }
  }

  private async handleBraintreeError(message: string, error: any): Promise<void> {
    if (this.hostedFieldsInstance) {
      await this.hostedFieldsInstance.teardown();
    }
    logger.error(message, error, {
      code: error.code,
      type: error.type,
    });

    this.error.emit(error.message);
  }

  private initHolderNameForm(): void {
    this.holderNameForm = this.formBuilder.group({
      holderName: ['', [Validators.required]],
    });
  }

  private getBraintreeConfig(): { fields: HostedFieldFieldOptions; styles?: any } {
    return {
      fields: {
        number: {
          container: `#${this.cardNumberSelector}`,
          placeholder: this.translateService.instant('PAYMENT.PAYMENT_FORM.PLACEHOLDER.CARD_NUMBER'),
        },
        expirationDate: {
          container: `#${this.cardExpirationSelector}`,
          placeholder: this.translateService.instant('PAYMENT.PAYMENT_FORM.PLACEHOLDER.EXPIRATION_DATE'),
        },
        cvv: {
          container: `#${this.cardSecurityCodeSelector}`,
          placeholder: this.translateService.instant('PAYMENT.PAYMENT_FORM.PLACEHOLDER.SECURITY_CODE'),
        },
      },
      styles: {
        input: {
          'font-size': '17px',
          'font-family': '"Helvetica Neue", Helvetica, sans-serif',
          '-webkit-font-smoothing': 'antialiased',
        },
        'input::placeholder': {
          color: 'rgba(14, 43, 66, 0.3)',
        },
      },
    };
  }

  handleValidityChange(event: HostedFieldsEvent): void {
    if (event.fields[event.emittedBy].isValid) {
      this.errors[event.emittedBy] = false;
    }

    this.isBraintreeFormValid = !Object.values(event.fields).some((field: HostedFieldsHostedFieldsFieldData) => !field.isValid);

    this.changeDetectorRef.detectChanges();
  }

  handleInputBlur(event: HostedFieldsEvent): void {
    this.errors[event.emittedBy] = event.fields[event.emittedBy].isValid === false;

    this.changeDetectorRef.detectChanges();
  }
}
