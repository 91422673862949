<div class="container" data-test="marketing-modal">
  <div>
    <div class="text">
      <div class="h4_h3-regular header">{{ 'CUSTOMER_REVIEW_MODAL.TITLE' | translate }}</div>
      <p class="p-md">{{ 'CUSTOMER_REVIEW_MODAL.BODY' | translate }}</p>
    </div>
    <div class="actions">
      <ni-button-rb data-test="leave-review-button" (click)="onLeaveReview()">
        {{ 'CUSTOMER_REVIEW_MODAL.REVIEW_BUTTON' | translate }}
      </ni-button-rb>
      <div class="p-md skip" data-test="skip-button" (click)="onSkip()">
        {{ 'CUSTOMER_REVIEW_MODAL.SKIP' | translate }}
      </div>
    </div>
  </div>
  <div class="img-container">
    <img src="/assets/img/customer-reviews-modal.svg" alt="completed-icon" />
  </div>
</div>
