import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NiButtonRbComponent, NiDesktopOnlyDirective, NiMobileOnlyDirective } from '@next-insurance/ni-material';
import { NiTrimInputDirective } from '@next-insurance/ni-material/ni-trim-input-directive';
import { TranslateModule } from '@ngx-translate/core';
import { InputMaskModule } from 'primeng/inputmask';

import { FormErrorMessageDirective } from '../shared/directives/form-validation/form-error-message.directive';
import { PhonePipe } from '../shared/pipes/phone.pipe';
import { OnboardingAmazonSellerInformationStepComponent } from './components/onboarding-modal/onboarding-amazon-seller-step/onboarding-amazon-seller-information-step.component';
import { OnboardingDownloadAppStepComponent } from './components/onboarding-modal/onboarding-download-app-step/onboarding-download-app-step.component';
import { OnboardingFinalStepComponent } from './components/onboarding-modal/onboarding-final-step/onboarding-final-step.component';
import { OnboardingModalComponent } from './components/onboarding-modal/onboarding-modal.component';
import { OnboardingEditEmailComponent } from './components/onboarding-modal/onboarding-verification-step/onboarding-edit-identity/onboarding-edit-email/onboarding-edit-email.component';
import { OnboardingEditPhoneComponent } from './components/onboarding-modal/onboarding-verification-step/onboarding-edit-identity/onboarding-edit-phone/onboarding-edit-phone.component';
import { OnboardingEmailVerificationStepComponent } from './components/onboarding-modal/onboarding-verification-step/onboarding-email-verification-step/onboarding-email-verification-step.component';
import { OnboardingPhoneVerificationStepComponent } from './components/onboarding-modal/onboarding-verification-step/onboarding-phone-verification-step/onboarding-phone-verification-step.component';
import { OnboardingTrackingService } from './services/onboarding-tracking.service';

@NgModule({
  declarations: [
    OnboardingModalComponent,
    OnboardingEmailVerificationStepComponent,
    OnboardingFinalStepComponent,
    OnboardingEditEmailComponent,
    OnboardingDownloadAppStepComponent,
    OnboardingPhoneVerificationStepComponent,
    OnboardingEditPhoneComponent,
    OnboardingAmazonSellerInformationStepComponent,
  ],
  imports: [
    CommonModule,
    PhonePipe,
    ReactiveFormsModule,
    InputMaskModule,
    FormErrorMessageDirective,
    NiTrimInputDirective,
    NiMobileOnlyDirective,
    NiDesktopOnlyDirective,
    NiButtonRbComponent,
    TranslateModule,
  ],
  exports: [OnboardingModalComponent],
  providers: [OnboardingTrackingService],
})
export class OnboardingModule {}
