export enum FeatureFlags {
  // Permanent flags
  UrgentUserMessage = 'urgent_user_message',
  Onboarding = 'onboarding',
  SpecialOccasionWelcomeBanner = 'special_occasion_welcome_banner',
  HelpCenter = 'help_center',
  CHBGooglePlacesApi = 'chb_google_places_api',

  // Temporary flags
  MultiLocationsV2 = 'multi_locations_v2',
  BOP = 'bop_new_lob',
  AutoPoliciesCrossSell = 'auto_policies_cross_sell',
  ReferralProgram = 'referral_program',
  CustomerReviewModal = 'customer_review_modal',
  NextLogin = 'next_login',
  HealthInsuranceCrossSell = 'health_insurance_cross_sell',
  ApIntigoNavigationLink = 'ap_intigo_navigation_link',
  MobileChatBtn = 'mobile_chat_btn',
  UserInteractionsSqsClient = 'user_interactions_sqs_client',
  UnhandledRejectionDebug = 'unhandled_rejection_debug',
  SurvicateFeedback = 'survicate_feedback',
  TransactionFee = 'transaction_fee',
}
