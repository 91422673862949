import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { businessSelectors } from '../../business/store/business.selectors';
import { AppState } from '../../store';
import { BusinessInformationAssortmentFlowType } from '../models/business-information-assortment-flow-type.enum';
import { NavigationService } from './navigation.service';
import { UserAgentService } from './user-agent.service';

@Injectable({
  providedIn: 'root',
})
export class MobileAppService {
  private readonly webviewLandingPagesPatterns = [
    { pattern: /\/certificates\/create-certificate\/pre-questions$/ },
    {
      pattern: /\/certificates\/create-certificate\/options$/,
      conditionFunc: () => !this.isEligibleForCertificatePreQuestionsPage(),
    },
    { pattern: /\/certificates\/followers/ },
    { pattern: /\/certificates\/third-party-requests\/notifications/ },
    { pattern: /\/certificates\/third-party-requests\/changes/ },
    { pattern: /\/certificates\/live-certificate/ },
    { pattern: /\/coverage\// },
  ];

  constructor(
    private cookieService: CookieService,
    private userAgentService: UserAgentService,
    private navigationService: NavigationService,
    private store: Store<AppState>,
  ) {}

  isMobileAppWebview(): boolean {
    return this.cookieService.get(environment.mobileAppWebviewCookieName) === 'true';
  }

  isDownloadAppEnabled(): boolean {
    return this.userAgentService.isAndroid() || this.userAgentService.isIos();
  }

  downloadApp(): void {
    this.navigationService.navigateTo('https://nextinsurance.page.link/wT16');
  }

  isVersionGreaterOrEqual(mobileVersion: string): boolean {
    return (
      this.cookieService
        .get(environment.mobileAppVersionCookieName)
        .localeCompare(mobileVersion, undefined, { numeric: true, sensitivity: 'base' }) >= 0
    );
  }

  canEditContactInfo(): boolean {
    return this.isMobileAppWebview() && this.isVersionGreaterOrEqual('1.0.4');
  }

  isWebviewLandingPage(path: string): boolean {
    return this.webviewLandingPagesPatterns.some(
      (landingPage) => path.match(landingPage.pattern) && (!landingPage.conditionFunc || landingPage.conditionFunc()),
    );
  }

  private isEligibleForCertificatePreQuestionsPage(): boolean {
    let isEligibleForPreQuestionsPage: boolean;
    this.store
      .select(businessSelectors.getBusinessInformationAssortmentEligibility(BusinessInformationAssortmentFlowType.CoiQuestions))
      .pipe(first())
      .subscribe((isEligible) => {
        isEligibleForPreQuestionsPage = isEligible;
      });

    return isEligibleForPreQuestionsPage;
  }
}
