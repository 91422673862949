import { NIMetadata, NIMetadataBuilder } from '@next-insurance/ng-core';
import { select, Selector, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { businessSelectors } from '../../../business/store/business.selectors';
import { AppState } from '../../../store';
import { coreSelectors } from '../../store/core.selectors';

function getCurrentStoreValue(store: Store<AppState>, selector: Selector<AppState, string>): string {
  let currentStoreValue: string;
  store.pipe(select(selector), first()).subscribe((value) => {
    currentStoreValue = value;
  });

  return currentStoreValue;
}

export function metadataBuilderFactory(store: Store<AppState>, cookieService: CookieService): NIMetadataBuilder {
  return (): NIMetadata => {
    const userType = getCurrentStoreValue(store, coreSelectors.getUserType) || undefined;
    const businessId = getCurrentStoreValue(store, businessSelectors.getBusinessId) || undefined;
    const trackingId = cookieService.get(environment.tidCookieName) || undefined;
    const isMobileWebview = cookieService.get(environment.mobileAppWebviewCookieName) === 'true';

    return {
      ...(userType && { userType }),
      ...(businessId && { businessId }),
      ...(trackingId && { trackingId }),
      isMobileWebview: isMobileWebview ? 'true' : 'false',
    };
  };
}
