import { DOCUMENT, NgForOf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { AchFailureCategory } from '@next-insurance/core';
import { FeedbackType, NiSystemFeedbackComponent } from '@next-insurance/ni-material';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ZendeskIssueTag } from '../../../core/models/zendesk-issue-tag.enum';
import { ZendeskService } from '../../../core/services/zendesk.service';

@Component({
  selector: 'ni-ach-failed-payment-notice',
  templateUrl: './ach-failed-payment-notice.component.html',
  styleUrls: ['./ach-failed-payment-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NiSystemFeedbackComponent, TranslateModule, NgForOf],
})
export class AchFailedPaymentNoticeComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) achFailureCategory: AchFailureCategory;
  fixInstructions: string[] = [];
  protected readonly FeedbackType = FeedbackType;

  constructor(
    private translateService: TranslateService,
    private zendeskService: ZendeskService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.fixInstructions = this.getFixInstructions();
  }

  ngAfterViewInit(): void {
    this.getAllChatLinks().forEach((chatLinkElement: Element) => {
      chatLinkElement.addEventListener('click', () => {
        this.openZendeskChat();
      });
    });
  }

  private getFixInstructions(): string[] {
    return Object.values(this.translateService.instant(`FAILED_PAYMENT_NOTICE.ACH.${this.achFailureCategory}.FIX_INSTRUCTIONS`));
  }

  private getAllChatLinks(): NodeListOf<Element> {
    return this.document.querySelectorAll('a.chat-link');
  }

  private openZendeskChat(): void {
    this.zendeskService.openZendeskChat(undefined, [ZendeskIssueTag.AchFailedPaymentIssue]);
  }
}
