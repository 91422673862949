import { HttpErrorResponse } from '@angular/common/http';
import { NIStringHelper } from '@next-insurance/utils';
import { EventHint } from '@sentry/core';

import {
  isAnyHttpErrorResponse,
  isHttpErrorResponse,
  isHttpErrorResponseMessage,
  isHttpErrorResponseRejection,
  isHttpErrorResponseString,
} from '../../helpers/is-http-error-response';
import { errorsFilterConfig } from '../errors-filter.config';

function getUrlAndStatus(error: string | HttpErrorResponse | { rejection: HttpErrorResponse } | { message: string }): {
  status: number;
  url: string;
} {
  let url: string;
  let status: number;

  if (isHttpErrorResponse(error)) {
    url = error.url;
    status = error.status;
  } else if (isHttpErrorResponseRejection(error)) {
    url = error.rejection.url;
    status = error.rejection.status;
  } else if (isHttpErrorResponseMessage(error) || isHttpErrorResponseString(error)) {
    let message: string;
    if (isHttpErrorResponseMessage(error)) {
      message = error.message;
    } else if (isHttpErrorResponseString(error)) {
      message = error;
    }

    const head = NIStringHelper.getBefore(message, ': ');
    const tail = NIStringHelper.getAfter(message, ': ');
    url = NIStringHelper.getAfter(head, ' for ');
    status = +NIStringHelper.getBefore(tail, ' ');
  }

  return {
    url,
    status,
  };
}

/**
 * Filter API errors.
 * @param hint Sentry hint
 * @returns True for filtering the error event, otherwise false
 */
export function apiErrorFilter(hint?: EventHint): boolean {
  const error = hint?.originalException;

  if (!isAnyHttpErrorResponse(error)) {
    return false;
  }

  const { statusCodes, apiUrls } = errorsFilterConfig.filters.apiErrorFilter;
  const { status, url } = getUrlAndStatus(error);

  return statusCodes.includes(status) && apiUrls.some((apiUrl) => url.startsWith(apiUrl));
}
