import { NgClass, NgIf, NgTemplateOutlet, TitleCasePipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AchFailureCategory, LOB } from '@next-insurance/core';
import { ButtonType, NiButtonRbComponent } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CardType } from '../../../core/models/card-type.enum';
import { HelpTip } from '../../../core/models/help-tip.enum';
import { ZendeskIssueTag } from '../../../core/models/zendesk-issue-tag.enum';
import { ZendeskService } from '../../../core/services/zendesk.service';
import { PaymentDetailsPerPolicy } from '../../../payment/models/payment-details-per-policy.model';
import { PaymentMethodDetails, PaymentMethodType } from '../../../payment/models/payment-method-details.model';
import { PaymentService } from '../../../payment/payment.service';
import { paymentSelectors } from '../../../payment/store/payment.selectors';
import { Policy } from '../../../policies/models/policy.model';
import { policiesSelectors } from '../../../policies/store/policies.selectors';
import { AppState } from '../../../store';
import { HelpTipDirective } from '../../directives/help-tip.directive';
import { LobNamePipe } from '../../pipes/lob-name.pipe';
import { LobNameService } from '../../services/lob-name.service';
import { AchFailedPaymentNoticeComponent } from '../ach-failed-payment-notice/ach-failed-payment-notice.component';

@Component({
  selector: 'ni-payment-method-details',
  templateUrl: './payment-method-details.component.html',
  styleUrls: ['./payment-method-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [LobNamePipe],
  imports: [
    AchFailedPaymentNoticeComponent,
    NgTemplateOutlet,
    TranslateModule,
    NiButtonRbComponent,
    NgIf,
    NgClass,
    TitleCasePipe,
    HelpTipDirective,
    TooltipModule,
    UpperCasePipe,
  ],
})
export class PaymentMethodDetailsComponent implements OnInit {
  readonly NA = 'N/A';
  @Output() updatePaymentMethodClicked = new EventEmitter<void>();
  @Output() openZendeskChatClicked = new EventEmitter<void>();
  @Input() hidePayAsYouGoDetails = false;
  protected readonly HelpTip = HelpTip;
  private destroyRef = inject(DestroyRef);
  concatenatedLobNames: string;
  concatenatedPayGoLobNames: string;
  paymentMethodDetails: PaymentMethodDetails;
  achFailureCategory: AchFailureCategory;
  PaymentMethodType = PaymentMethodType;
  AchFailureCategory = AchFailureCategory;
  protected readonly ButtonType = ButtonType;

  protected mapCardTypeToIconSvg: Record<CardType, string> = {
    [CardType.Visa]: 'visa.svg',
    [CardType.Mastercard]: 'mastercard.svg',
    [CardType.Amex]: 'amex.svg',
    [CardType.Discover]: 'discover.svg',
    [CardType.JCB]: 'jcb.svg',
    [CardType.Diners]: 'diners.svg',
  };

  constructor(
    private paymentService: PaymentService,
    private zendeskService: ZendeskService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private lobNameService: LobNameService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select(paymentSelectors.getPaymentMethodDetails),
      this.store.select(paymentSelectors.getPaymentDetailsPerPolicy),
      this.store.select(policiesSelectors.getPayAsYouGoPolicies),
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(
          ([paymentMethodDetails, paymentDetailsPerPolicy, payAsYouGoPolicies]: [
            PaymentMethodDetails,
            PaymentDetailsPerPolicy,
            Policy[],
          ]) => {
            this.paymentMethodDetails = paymentMethodDetails;
            this.concatenatedPayGoLobNames = this.constructLobNamesString(payAsYouGoPolicies);
            this.concatenatedLobNames = this.constructLobNamesString(Object.values(paymentDetailsPerPolicy));
            this.achFailureCategory = this.paymentService.getAchFailureCategory(paymentMethodDetails);
            this.cdr.markForCheck();
          },
        ),
      )
      .subscribe();
  }

  constructLobNamesString(arr: { lob: LOB }[]): string {
    return arr
      .map((policy) => this.lobNameService.getLobName(policy.lob))
      .join(', ')
      .toUpperCase();
  }

  navigateToApIntego(): void {
    this.paymentService.navigateToApIntego();
  }

  openZendeskChat(): void {
    this.zendeskService.openZendeskChat(undefined, [ZendeskIssueTag.AchFailedPaymentIssue]);
    this.openZendeskChatClicked.emit();
  }

  onUpdatePaymentMethodClicked(): void {
    this.updatePaymentMethodClicked.emit();
  }
}
