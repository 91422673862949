import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Inject, Injectable, Injector, Type } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter } from 'rxjs/operators';

export type NIDynamicDialogConfig<T = any> = {
  narrow?: boolean;
} & DynamicDialogConfig<T>;

@Injectable({
  providedIn: 'root',
})
export class DynamicDialogService extends DialogService {
  openedDialogs: Map<string, DynamicDialogRef> = new Map();

  constructor(
    appRef: ApplicationRef,
    injector: Injector,
    private router: Router,
    private deviceDetectorService: DeviceDetectorService,
    @Inject(DOCUMENT) document: Document,
  ) {
    super(appRef, injector, document);
    this.closeAllDialogsOnNavigation();
  }

  open<T>(componentType: Type<any>, dynamicDialogConfig: NIDynamicDialogConfig<T> = {}): DynamicDialogRef {
    const dialogRef = super.open(componentType, this.buildConfig(dynamicDialogConfig));
    this.openedDialogs.set(componentType.name, dialogRef);
    this.deleteDialogOnClose(dialogRef, componentType);
    return dialogRef;
  }

  close(componentType: Type<any>): void {
    const dialogRef = this.openedDialogs.get(componentType.name);
    if (dialogRef) {
      dialogRef.close();
    }
  }

  private buildConfig(dynamicDialogConfig: NIDynamicDialogConfig): DynamicDialogConfig {
    return {
      styleClass: dynamicDialogConfig.header ? '' : 'ni-dialog-no-header',
      position: this.deviceDetectorService.isMobile() ? 'bottom' : 'center',
      transitionOptions: '0ms',
      dismissableMask: true,
      width: dynamicDialogConfig.narrow ? '600px' : '880px',
      duplicate: true,
      ...dynamicDialogConfig,
    };
  }

  private deleteDialogOnClose(dialogRef: DynamicDialogRef, componentType: Type<any>): void {
    dialogRef.onClose.subscribe(() => {
      this.openedDialogs.delete(componentType.name);
    });
  }

  private closeAllDialogsOnNavigation(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      if (!this.isSameUrlNavigation(event)) {
        for (let dialogRef of this.openedDialogs.values()) {
          dialogRef.close();
        }
      }
    });
  }

  private isSameUrlNavigation(navigationStartEvent: NavigationStart): boolean {
    const currentUrlWithoutQueryParams = this.getUrlWithoutQueryParams(this.router.url);
    const nextUrlWithoutQueryParams = this.getUrlWithoutQueryParams(navigationStartEvent.url);
    return currentUrlWithoutQueryParams === nextUrlWithoutQueryParams;
  }

  private getUrlWithoutQueryParams(url: string): string {
    return url.split('?')[0];
  }
}
