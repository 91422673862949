import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { NiButtonRbComponent } from '@next-insurance/ni-material';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { MarketingModalUtilService } from '../../../../core/services/marketing-modal-util.service';
import { ReferralProgramService } from '../../../../core/services/referral-program.service';
import { coreActions } from '../../../../core/store/core.actions';
import { AppState } from '../../../../store';

@Component({
  selector: 'ni-referral-program-modal',
  templateUrl: './referral-program-modal.component.html',
  styleUrls: ['./referral-program-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NiButtonRbComponent, TranslateModule],
})
export class ReferralProgramModalComponent implements OnInit {
  private store = inject<Store<AppState>>(Store);
  private dynamicDialogRef = inject(DynamicDialogRef);
  private referralProgramService = inject(ReferralProgramService);
  private marketingModalUtilService = inject(MarketingModalUtilService);

  ngOnInit(): void {
    this.store.dispatch(coreActions.setShowMarketingModal({ showMarketingModal: false }));
  }

  onReferAFriend(): void {
    this.marketingModalUtilService.setNeverShowReferralModal();
    this.referralProgramService.trackReferralProgramClick('referral-program-modal');
    this.dynamicDialogRef.close();
    this.referralProgramService.onClickReferralProgram();
  }

  onSkip(): void {
    this.referralProgramService.trackReferralProgramModalSkipButtonClick();
    this.dynamicDialogRef.close();
  }
}
